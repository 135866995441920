import { observable } from 'mobx'
import { validationContext } from 'validx'

export default class DiyConfigViewModel {
  @observable
  consultationLegendId = undefined

  @observable
  workspaceConsultationLegends = []

  validation = validationContext(this, {})

  constructor({ legendStore, workspaceId }) {
    this.legendStore = legendStore
    this.workspaceId = workspaceId
  }

  activate(consultationLegendId = undefined) {
    this.consultationLegendId = consultationLegendId

    this.workspaceConsultationLegends = this.legendStore.briefConsultationLegendsForWorkspace(
      this.workspaceId
    )
  }

  toJSON() {
    return {
      consultation_legend_id: this.consultationLegendId ?? '',
    }
  }
}
